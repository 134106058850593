@import "/src/spin.scss";

$tableRadius: 6px;

span.text {
  font-size: 16px !important;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
}

#infoCard {
  height: 60vh;
  padding: 100px 100px 100px 20px;
  background-color: whitesmoke;
  text-align: left;
}

.btn-custom {
  background-color: $customColor !important;
  border-color: $customColor !important;
  color: white !important;
  width: 194px;
  height: 40px;

  &:disabled {
    color: #ffffff !important;
    border-color: #d9d9d9 !important;
    background-color: #5e7887 !important;
  }
}

.btn-outline-custom {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 1px $customColor !important;
  color: $customColor !important;
  width: 194px;
  height: 40px;
}

.link-custom,
.link-custom:hover {
  color: $customColor !important;
  text-decoration: none;
}

.link-custom-filled,
.link-custom-filled:hover {
  color: white !important;
  text-decoration: none;
}

.table-custom table {
  border-collapse: separate;
  border-spacing: 0px 10px;

  th {
    background-color: whitesmoke !important;
    margin-right: 15px !important;
    border-right: 2px solid white;
    color: $fontColor !important;
    border-bottom: none !important;
    font-weight: 600;
    font-size: 16px;
    opacity: 85%;
    line-height: 22px;
    &:last-child {
      border-right: unset;
    }

    &::before {
      display: none;
    }
  }

  td {
    border-bottom: none !important;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: $tableRadius;
    height: 71px;

    td:first-child {
      font-weight: 400;
    }

    td:not(.col-azioni) span {
      color: $fontColor;
      opacity: 85%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: clip;
      max-height: 100%;
      white-space: normal;
      display: -webkit-box;
    }
  }

  td:first-child {
    border-top-left-radius: $tableRadius;
    border-bottom-left-radius: $tableRadius;
  }

  td:last-child {
    border-top-right-radius: $tableRadius;
    border-bottom-right-radius: $tableRadius;
  }

  .ant-table-placeholder {
    height: 234px;
  }
}

.table-custom.consultori {
  tr td:first-child {
    padding-left: 40px;
    text-align: left !important;
  }

  .ant-table-expanded-row-fixed {
    width: unset !important;
  }
}

#infoCardAction {
  padding-top: 40px;
}

.modal-centri-vaccinali {
  height: 639px;

  .ant-modal-body {
    padding-top: 60px !important;
    padding-left: 50px;
    padding-right: 50px;
  }

  .ant-modal-footer {
    border-top: unset;
  }
}

@media (max-width: 576px) {
  .modal-centri-vaccinali {
    top: 5px;

    .ant-modal-body {
      padding-top: 24px !important;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .centri-vaccinali-scrollable-section {
    height: 155px !important;
  }

  .leaflet-container {
    min-height: 180px !important;
  }

  h3 {
    margin: auto;
  }
}

.custom-divider {
  border-top: 2px solid #e5e5e5;
  padding-top: 20px;
}

.centri-vaccinali-scrollable-section {
  height: 336px;
  overflow: hidden;
  overflow-y: scroll;
}

.centri-vaccinali-scrollable-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #e5e5e5;
}

.centri-vaccinali-scrollable-section::-webkit-scrollbar {
  width: 5px;
  background-color: #e5e5e5;
}

.centri-vaccinali-scrollable-section::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5e7887;
}

.table-row-dark {
  background-color: #f4f5f6;
}

.table-row-light {
  background-color: white;
}

.circle-justify-center {
  width: 80px;
  height: 80px;
  background-color: #d4c207;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &.aggiungi-assistito {
    background-color: $customColor;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: $customColor;
}

.ant-radio-inner {
  border: 1px solid #5c6f82;
}

.ant-radio-inner::after {
  background-color: $customColor;
  width: 14px;
  height: 14px;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $customColor;
}

span.ant-radio-inner {
  width: 22px;
  height: 22px;
}

.ant-table-cell.ant-table-selection-column {
  border-right: unset;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

@media (max-width: 432px) {
  .logo svg {
    width: 335px;
  }
}

.ant-switch-checked > .ant-switch-handle::before {
  background-color: $customColor;
}

.ant-switch-checked {
  background-color: rgba(128, 128, 128, 0.25);
}

.btn-search {
  background-color: $customColor !important;
  border-color: $customColor !important;
  color: white !important;
  width: 96px;
  height: 40px;
  margin-left: 30px;
}

#formSearch,
#formSearchFilter {
  label {
    strong {
      font-size: 16px;
      color: #868686;
    }
  }
}

.card-warning {
  border: 1px solid #d97e00;
  margin-top: 45px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
  text-align: left;

  .card-inner-text:nth-child(1) {
    padding: 20px 55px 0px 55px;
  }

  .card-inner-text:nth-child(2) {
    padding: 0px 55px 20px 55px;
  }
}

.card-title-left {
  position: relative;
  top: 59px;
  width: 215px;
  white-space: nowrap;
  background: white;
  margin-left: 20px;
  z-index: 20;

  span {
    text-transform: uppercase;
    margin-left: 12px;
    color: #d97e00;
  }
}

.ant-select-selection-item {
  color: #5e7887;
  padding: 8px 20px 8px 0px;
  text-align: left;
  align-self: center;
}

.ant-select-selector-item {
  height: 40px;
}

#stato {
  .ant-select-selector {
    height: 40px;
  }

  .ant-select-arrow {
    color: #5e7887;
  }
}

.custom-collapse-header > .ant-collapse-item > .ant-collapse-header {
  padding-top: 32px;
  padding-bottom: 50px;
  padding-left: 45px;
}

.custom-collapse-header
  > .ant-collapse-item
  > .ant-collapse-header[aria-expanded="true"] {
  padding-bottom: 20px;
}

.custom-collapse-header
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-left: 45px;
  padding-bottom: 45px;
}

.dose {
  &-non-completata {
    margin-left: 20px;
    background: white;
    border-color: #fbb800;
    color: #5c6f82;
  }

  &-completata {
    margin-left: 20px;
    background: white;
    border-color: #009a6d;
    color: #5c6f82;
  }
}

.table-dosi table {
  border-collapse: separate;
  border-spacing: 0px 10px;

  th {
    background-color: whitesmoke !important;
    margin-right: 15px !important;
    //border-bottom: 10px solid white !important;
    border-right: 2px solid white;
    color: #003354 !important;
    border-bottom: none !important;
    font-weight: 600;
    font-size: 16px;
  }

  td {
    border-bottom: none !important;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr.ant-table-row {
    border-radius: $tableRadius;
    height: 71px;

    td {
      border-bottom: 1px solid #baccd9 !important;
      border-top: 1px solid #baccd9;

      &:first-child {
        border-left: 1px solid #baccd9;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-right: 1px solid #baccd9;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.filter-form {
  display: inline-flex;
  align-items: flex-end;

  .btn-search {
    margin-left: unset;
  }
}

@media (max-width: 767px) {
  .filter-form {
    display: contents;
    align-items: flex-end;
  }

  .select-filter {
    margin-left: unset !important;
  }

  #stato {
    .ant-form-item-label {
      padding-left: unset !important;
    }
  }
}

td.ant-table-cell span:empty {
  &::after {
    content: "-";
    text-align: center;
  }
}

.ant-table-cell {
  text-align: start !important;
  padding-left: 20px !important;
}

.modal-utente {
  overflow: hidden;

  .ant-modal-close {
    display: none;
  }

  .ant-carousel .space-dots {
    .ant-space-item {
      display: flex;
      margin-bottom: 11px;
    }

    li {
      border-radius: 50%;
      width: 10px;
      height: 10px;

      button {
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.ant-spin-text {
  color: $customColor;
  display: block;
}

@media (max-width: 768px) {
  .btn-download {
    margin-top: 20px;
  }
}

.image-carousel {
  height: 100%;
}

.divider {
  border-top: 2px solid #e5e5e5;
  margin-top: 16px;

  &-search {
    margin-bottom: 30px;
  }

  &-search-filter {
    margin-bottom: 40px;
  }
}

.ant-empty-normal {
  .ant-empty-description {
    color: $fontColor !important;
    opacity: 85%;
  }
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.button-guida-utente {
  position: relative;
  bottom: 5px;
  right: 20px;
  z-index: 20;
  display: flex;
  justify-content: end;
}

@media (max-width: 1128px) {
  .button-guida-utente {
    position: relative;
    bottom: 20px;
    right: 20px;
    z-index: 20;
    display: flex;
    justify-content: end;
  }
}

.ant-btn-icon-only > .anticon {
  display: unset;
}

.ant-pagination-item a {
  color: $fontColor !important;
}

.ant-pagination-item-active a {
  color: $customColor !important;
}

.ant-pagination-disabled .ant-pagination-item-link {
  color: #d9d9d9 !important;
}

.carousel-percorsi {
  margin-bottom: 50px;
  .carousel-item {
    position: relative;
    background-color: grey;
    .description-section {
      position: absolute;
      right: 0;
      height: 450px;
      width: 30%;
      z-index: 20;
      background: rgba(0, 0, 0, 0.25);
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.5);
      padding: 20px;
      span.ant-typography {
        font-weight: 400;
        position: absolute;
        bottom: 11px;
        right: 20px;
      }

      .ant-typography {
        text-align: left;
        color: white;
        font-weight: 400;
        line-height: 24px;
        white-space: break-spaces;
      }
    }
    @media (max-width: 992px) {
      .description-section {
        bottom: 0;
        height: 50%;
        width: 100%;
        span.ant-typography {
          bottom: 47px;
        }
        .ant-typography {
          &.ant-typography-ellipsis {
            -webkit-line-clamp: 4 !important;
          }
        }
      }
    }
  }

  .slick-track {
    height: 450px;
  }
}

.tab-percorso {
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  height: 973px;
  overflow: hidden;

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    height: 144px;
    width: 320px;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    .icon-percorsi {
      display: block;
    }

    .active-icon-percorsi {
      display: none;
    }

    .ant-tabs-tab-btn {
      width: 320px;
    }

    h5 {
      color: $customColor;
      text-align: left;
    }

    &.ant-tabs-tab-active {
      background: $customColor;
      h5 {
        color: white;
      }

      .icon-percorsi {
        display: none;
      }

      .active-icon-percorsi {
        display: block;
      }
    }
  }

  .ant-tabs-content-holder {
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border: 3px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 4px;
    }

    .ant-tabs-tabpane {
      padding: 24px !important;
    }
  }

  .ant-tabs-nav-more {
    display: none;
  }

  @media (max-width: 992px) {
    .ant-tabs-tab {
      height: 70px;
      width: unset;

      h5 {
        font-size: 20px !important;
      }
    }

    .icon-percorsi {
      display: none !important;
    }

    .active-icon-percorsi {
      display: none !important;
    }

    #mappa {
      display: none;
    }
  }

  @media (min-width: 992px) {
    /*.ant-tabs-nav-wrap {
      overflow-y: scroll !important;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: grey;
        border: 3px solid transparent;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
      }

      .ant-tabs-nav-list {
        transform: unset !important;
       }}*/
  }
}

.spin-api.ant-spin {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  position: fixed !important;
  top: 20% !important;
  .ant-spin-text {
    align-self: center;
    width: unset !important;
  }
}
.ant-card.card-percorsi {
  text-align: left;
  margin-top: 30px;
  &.ant-card-bordered {
    border: 1px solid #f0f0f0;
  }

  .ant-card-head-wrapper {
    font-size: 16px;
    opacity: 0.85;
  }

  .ant-card-body {
    font-size: 20px;
    line-height: 22px;
    opacity: 0.45;
    white-space: break-spaces;
  }
}

@media (min-width: 992px) {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    content: ">";
    font-size: 27px;
    transform: rotate(90deg);
    width: 45px;
    height: 45px;
    margin: auto;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    justify-content: center;
    display: flex;
    background: white;
    margin: 9px auto;
    text-align: center;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    content: ">";
    font-size: 27px;
    transform: rotate(-90deg);
    width: 45px;
    height: 45px;
    margin: auto;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    justify-content: center;
    display: flex;
    background: white;
    margin: 9px auto;
    text-align: center;
  }
}
