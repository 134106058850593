.selectRender {
  .ant-typography {
    font-size: 16px;
  }

  .ant-select-item {
    border-bottom: 0.5px solid #baccd9;

    &:last-child {
      border-bottom: unset;
    }
  }

  .ant-select-item-option:hover {
    background: #baccd9 !important;
  }

  .ant-select-item-option {
    background: white !important;
    padding: 12px 0px;
  }
}

.ant-select-dropdown {
  padding: 2px 15px;
}

.ant-select-selection-item .ant-typography {
  color: #868686;
}
