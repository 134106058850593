.no-shadow-modal {
    height: 100%;
  }
  
  .no-shadow-modal > .ant-modal-content {
    box-shadow: 0 0px 0px rgba(0,0,0,0);
    height: 100%;
  }
  
  .ant-modal-wrap {
    z-index: 1012;
  }







