.box-search {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 21px;
  padding-right: 21px;
  margin-bottom: 40px;
  .ant-card-body {
    padding: 5px;
  }
  .ant-row {
    word-break: break-word;

    .ant-col {
      .ant-space {
        text-align: left;

        .ant-space-item:first-child {
          .ant-typography {
            color: #003354;
          }
        }
      }
    }
  }
}
